<template>
  <v-container fluid>
    <v-row align="center">
      <v-col>
        <v-select
          v-model="filterBy"
          :items="filterByList"
          hide-details
          dense
          filled
          label="Filtrar por"
          @change="handleFilterSelect"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="orderBy"
          :items="orderByList"
          hide-details
          dense
          filled
          label="Ordenar por"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="orderType"
          :items="orderTypeList"
          hide-details
          dense
          filled
          label="Tipo de ordenação"
        ></v-select>
      </v-col>

      <v-col class="shrink">
        <v-btn fab small elevation="0" color="primary" @click="submitFilter">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="start" class="mb-4">
      <v-col cols="2">
        <v-btn block color="primary" @click="exportAndDownload">Exportar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selected"
          :items="items"
          :headers="headers"
          :items-per-page="-1"
          :hide-default-footer="true"
          :loading="loading"
          single-select
          loading-text="Carregando dados..."
          @click:row="handleRowClick"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.hasCompleteProfile`]="{ item }">
            {{ item.hasCompleteProfile === true ? "Sim" : "Não" }}
          </template>
        </v-data-table>
        <v-row justify="center" class="mt-2">
          <v-col class="grow">
            <!-- <v-btn
              color="primary"
              :disabled="!hasNext"
              :loading="loading"
              @click="nextPage"
            >
              Carregar mais
            </v-btn> -->
            <div class="text-uppercase text-caption text-center">
              Total carregado: {{ items.length }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-expand-transition>
          <v-card v-if="selected.length > 0">
            <v-container>
              <v-row
                v-for="element in profileSchema"
                :key="element.value"
                class="profile-field"
              >
                <v-col class="py-1">
                  {{ element.text }}
                </v-col>
                <v-col class="py-1">
                  {{ formatField(selected[0][element.value]) }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import DataGetter from "../../plugins/admin-data";
import {
  getUsersWithUnfinishedProfile,
  getUsersWithCompletedProfile,
  getAllUsers,
} from "../../plugins/new-admin";
import moment from "moment";
const Papa = require("papaparse");

export default {
  data: () => ({
    getter: null,
    items: [],
    // hasNext: false,
    loading: false,
    orderBy: null,
    orderByList: [
      { text: "Nenhum campo", value: null },
      { text: "Data de Cadastro", value: "createdAt" },
      { text: "Finalizou cadastro", value: "hasCompleteProfile" },
      { text: "Estado", value: "state_casefold" },
      { text: "Faixa de idade", value: "age_casefold" },
    ],
    filterByList: [
      { text: "Nenhum campo", value: "allUsers" },
      { text: "Cadastro completo", value: "completedProfile" },
      { text: "Cadastro incompleto", value: "unfinishedProfile" },
    ],
    filterBy: null,
    orderType: "asc",
    orderTypeList: [
      { text: "Decrescente", value: "desc" },
      { text: "Ascedente", value: "asc" },
    ],
    // pageSize: 20,
    // pageSizeList: [
    //   { text: "20", value: 20 },
    //   { text: "50", value: 50 },
    //   { text: "100", value: 100 },
    //   // { text: "Mostrar tudo", value: 1000 },
    // ],
    selected: [],
  }),
  computed: {
    headers: function () {
      return [
        { value: "email", text: "Email", sortable: false },
        { value: "createdAt", text: "Cadastrado em", sortable: false },
        {
          value: "hasCompleteProfile",
          text: "Finalizou cadastro",
          sortable: false,
        },
        { value: "state", text: "Estado", sortable: false },
        { value: "age", text: "Faixa de idade", sortable: false },
      ];
    },
    profileSchema: function () {
      return [
        { value: "email", text: "Email" },
        { value: "name", text: "Nome" },
        { value: "state", text: "Estado" },
        { value: "city", text: "Cidade" },
        { value: "age", text: "Faixa de idade" },
        { value: "gender", text: "Gênero" },
        { value: "otherGender", text: "Gênero especificado" },
        { value: "ethnicity", text: "Cor, raça/etnia" },
        { value: "firstTimeVoting", text: "Votará pela primeira vez em 2022" },
        { value: "ocupation", text: "Atualmente" },
        { value: "isTeacher", text: "Professor" },
        { value: "teachingAt", text: "Ensina em" },
        { value: "hasDisability", text: "Possui deficiência" },
        { value: "disability", text: "Tipo de sua(s) deficiência(s)" },
        {
          value: "cameFrom",
          text: "Como ficou sabendo da Escola de Mudadores?",
        },
        {
          value: "humanRightsKnowledge",
          text: "Conhecimento sobre direitos humanos",
        },
        {
          value: "enviromentKnowledge",
          text: "Conhecimento sobre meio ambiente",
        },
        {
          value: "violenceKnowledge",
          text: "Conhecimento sobre enfrentamento da violência",
        },
        {
          value: "readyForElection",
          text: "Sente que está pronto para eleição",
        },
      ];
    },
    dataToExport: function () {
      return [
        { value: "email", text: "Email" },
        { value: "name", text: "Nome" },
        { value: "createdAt", text: "Cadastrado em" },
        { value: "hasCompleteProfile", text: "Finalizou cadastro" },
        { value: "state", text: "Estado" },
        { value: "city", text: "Cidade" },
        { value: "age", text: "Faixa de idade" },
        { value: "gender", text: "Gênero" },
        { value: "otherGender", text: "Gênero especificado" },
        { value: "ethnicity", text: "Cor/raça/etnia" },
        {
          value: "firstTimeVoting",
          text: "Votará pela primeira vez em 2022",
        },
        { value: "ocupation", text: "Atualmente" },
        { value: "isTeacher", text: "Professor" },
        { value: "teachingAt", text: "Ensina em" },
        { value: "hasDisability", text: "Possui deficiência" },
        { value: "disability", text: "Tipo de sua(s) deficiência(s)" },
        {
          value: "cameFrom",
          text: "Como ficou sabendo da Escola de Mudadores?",
        },
        {
          value: "humanRightsKnowledge",
          text: "Conhecimento sobre direitos humanos",
        },
        {
          value: "enviromentKnowledge",
          text: "Conhecimento sobre meio ambiente",
        },
        {
          value: "violenceKnowledge",
          text: "Conhecimento sobre enfrentamento da violência",
        },
        {
          value: "readyForElection",
          text: "Sente que está pronto para eleição",
        },
      ];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      moment.locale("pt");
      this.submitFilter();
      this.handleFilterSelect();
    },
    submitFilter() {
      this.items = [];
      switch (this.filterBy) {
        case "completedProfile":
          this.items = getUsersWithCompletedProfile(
            this.orderBy,
            this.orderType
          );
          break;
        case "unfinishedProfile":
          this.items = getUsersWithUnfinishedProfile(this.orderType);
          break;
        default:
          this.items = getAllUsers(this.orderType);
          break;
      }
      // this.getter = new DataGetter(
      //   [{ id: "users", type: "collection" }],
      //   {
      //     pageSize: this.pageSize,
      //     orderBy: this.orderBy,
      //     orderType: this.orderType,
      //   },
      //   this.handleDataLoadSuccessCallback,
      //   this.handleDataLoadErrorCallback
      // );
    },
    handleFilterSelect() {
      switch (this.filterBy) {
        case "completedProfile":
          this.orderByList = [
            { text: "Nenhum campo", value: null },
            { text: "Data de Cadastro", value: "createdAt" },
            { text: "Estado", value: "state_casefold" },
            { text: "Faixa de idade", value: "age_casefold" },
          ];
          break;
        case "unfinishedProfile":
          this.orderByList = [{ text: "Data de Cadastro", value: "createdAt" }];
          break;
        default:
          this.orderByList = [{ text: "Data de Cadastro", value: "createdAt" }];
          break;
      }
    },
    // nextPage() {
    //   if (this.loading) return;
    //   this.loading = true;
    //   this.getter.get(
    //     this.handleDataLoadSuccessCallback,
    //     this.handleDataLoadErrorCallback
    //   );
    // },
    // handleDataLoadSuccessCallback(data) {
    //   this.items = this.items.concat(data.items);
    //   this.hasNext = data.hasNext;
    //   this.loading = false;
    // },
    // handleDataLoadErrorCallback(error) {
    //   console.log(error);
    //   this.loading = false;
    // },
    handleRowClick(item) {
      if (this.selected.indexOf(item) > -1) this.selected = [];
      else this.selected = [item];
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatField(value) {
      if (value == null || value === "") return "-";
      if (value === true) return "Sim";
      if (value === false) return "Não";
      if (value instanceof Date) return this.formatDate(value);
      return value;
    },
    async exportAndDownload() {
      var formatedData = {
        fields: [],
        data: this.items,
      };

      var formatedValuesToExport = this.items.map((item) => {
        item.createdAt = new Date(item.createdAt).toLocaleDateString("pt-BR");
        item.hasCompleteProfile === true
          ? (item.hasCompleteProfile = "Sim")
          : (item.hasCompleteProfile = "Não");
        item.firstTimeVoting === true
          ? (item.firstTimeVoting = "Sim")
          : (item.firstTimeVoting = "Não");
        item.isTeacher === true
          ? (item.isTeacher = "Sim")
          : (item.isTeacher = "Não");
        item.hasDisability === true
          ? (item.hasDisability = "Sim")
          : (item.hasDisability = "Não");
        if (item.otherGender === "" || item.otherGender === null) {
          item.otherGender = "-";
        }
        if (item.teachingAt === "" || item.teachingAt === null) {
          item.teachingAt = "-";
        }
        if (item.disability === "" || item.disability === null) {
          item.disability = "-";
        }
        return item;
      });

      this.submitFilter();
      formatedData.data = formatedValuesToExport;

      this.dataToExport.forEach((header) => {
        formatedData.fields.push(header.value);
      });

      var csv = Papa.unparse(formatedData);

      this.dataToExport.forEach((header) => {
        csv = csv.replace(header.value, header.text);
      });

      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {
        type: "text/csv",
      });

      downloadLink = document.createElement("a");

      var fileName =
        new Date(Date.now())
          .toLocaleString()
          .split(",")[0]
          .replace("/", "_")
          .replace(" ", "_") + ".csv";
      fileName = `${fileName}`;

      downloadLink.download = fileName;

      downloadLink.href = window.URL.createObjectURL(csvFile);

      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);

      downloadLink.click();
    },
  },
};
</script>
<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
.profile-field {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.profile-field:last-of-type {
  border-bottom: 0;
}
</style>
