import { db } from "../plugins/firebase";

function getUsersWithUnfinishedProfile(orderType) {
  let users = [];
  if (!orderType) {
    db.collection("users")
      .where("hasCompleteProfile", "==", false)
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  } else {
    db.collection("users")
      .where("hasCompleteProfile", "==", false)
      .orderBy("createdAt", orderType)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  return users;
}

function getUsersWithCompletedProfile(orderField, orderType) {
  let users = [];

  if (!orderField || !orderType) {
    db.collection("users")
      .where("hasCompleteProfile", "==", true)
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  } else {
    db.collection("users")
      .where("hasCompleteProfile", "==", true)
      .orderBy(orderField, orderType)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  return users;
}

function getAllUsers(orderType) {
  let users = [];
  if (!orderType) {
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  } else {
    db.collection("users")
      .orderBy("createdAt", orderType)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  return users;
}

export {
  getUsersWithUnfinishedProfile,
  getUsersWithCompletedProfile,
  getAllUsers,
};
