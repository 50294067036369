<template>
  <v-container fluid>
    <v-row align="center">
      <v-col>
        <v-select
          v-model="orderBy"
          :items="orderByList"
          hide-details
          clearable
          dense
          filled
          label="Ordenar por"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="orderType"
          :items="orderTypeList"
          hide-details
          dense
          filled
          label="Tipo de ordenação"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="pageSize"
          :items="pageSizeList"
          hide-details
          dense
          filled
          label="Resultados por página"
        ></v-select>
      </v-col>
      <v-col class="shrink">
        <v-btn fab small elevation="0" color="primary" @click="submitFilter">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="start" class="mb-4">
      <v-col cols="2">
        <v-btn block @click="exportAndDownload" color="primary">Exportar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selected"
          :items="items"
          :headers="headers"
          :items-per-page="-1"
          :hide-default-footer="true"
          :loading="loading"
          item-key="email"
          single-select
          loading-text="Carregando dados..."
          @click:row="handleRowClick"
        >
          <template v-slot:[`item.openAt`]="{ item }">
            {{ formatDate(item.openAt) }}
          </template>
          <template v-slot:[`item.closedAt`]="{ item }">
            {{ formatDate(item.closedAt) }}
          </template>
          <template v-slot:[`item.hasCompleteProfile`]="{ item }">
            {{ item.hasCompleteProfile === true ? "Sim" : "Não" }}
          </template>
        </v-data-table>
        <v-row justify="center" class="mt-2">
          <v-col class="shrink">
            <v-btn
              color="primary"
              :disabled="!hasNext"
              :loading="loading"
              @click="nextPage"
            >
              Carregar mais
            </v-btn>
            <div class="text-uppercase text-caption text-center">
              Total carregado: {{ items.length }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-expand-transition>
          <v-card v-if="selected.length > 0">
            <v-container>
              <v-row
                v-for="element in profileSchema"
                :key="element.value"
                class="profile-field"
              >
                <v-col class="py-1">
                  {{ element.text }}
                </v-col>
                <v-col class="py-1">
                  {{ formatField(selected[0][element.value]) }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataGetter from "../../plugins/admin-data";
import moment from "moment";
const Papa = require("papaparse");

export default {
  data: () => ({
    items: [],
    hasNext: false,
    loading: false,
    orderBy: null,
    orderByList: [
      { text: "Nenhum campo", value: null },
      { text: "Email", value: "email" },
      { text: "Iniciou em", value: "openAt" },
      { text: "Encerrou em", value: "closedAt" },
    ],
    orderType: "desc",
    orderTypeList: [
      { text: "Decrescente", value: "desc" },
      { text: "Ascedente", value: "asc" },
    ],
    pageSize: 20,
    pageSizeList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "Mostrar tudo", value: -1 },
    ],
    selected: [],
  }),
  computed: {
    headers: function () {
      return [
        { value: "email", text: "Email", sortable: false },
        { value: "openAt", text: "Iniciou em", sortable: false },
        { value: "closedAt", text: "Encerrou em", sortable: false },
      ];
    },
    profileSchema: function () {
      return [
        { value: "email", text: "Email" },
        { value: "name", text: "Nome" },
        { value: "state", text: "Estado" },
        { value: "city", text: "Cidade" },
        { value: "age", text: "Faixa de idade" },
        { value: "gender", text: "Gênero" },
        { value: "otherGender", text: "Gênero especificado" },
        { value: "ethnicity", text: "Cor, raça/etnia" },
        { value: "firstTimeVoting", text: "Votará pela primeira vez em 2022" },
        { value: "ocupation", text: "Atualmente" },
        { value: "isTeacher", text: "Professor" },
        { value: "teachingAt", text: "Ensina em" },
        { value: "hasDisability", text: "Possui deficiência" },
        { value: "disability", text: "Tipo de sua(s) deficiência(s)" },
        {
          value: "cameFrom",
          text: "Como ficou sabendo da Escola de Mudadores?",
        },
        {
          value: "humanRightsKnowledge",
          text: "Conhecimento sobre direitos humanos",
        },
        {
          value: "enviromentKnowledge",
          text: "Conhecimento sobre meio ambiente",
        },
        {
          value: "violenceKnowledge",
          text: "Conhecimento sobre enfrentamento da violência",
        },
        {
          value: "readyForElection",
          text: "Sente que está pronto para eleição",
        },
        {
          value: "question01",
          text: "De 1 a 5 sendo 1 muito ruim e 5 muito bom, qual a sua opinião sobre a qualidade das videoaulas?",
        },
        {
          value: "question02",
          text: "De 1 a 5, sendo 1 muito ruim e 5 muito bom, qual a sua opinião sobre a qualidade dos materiais de apoio?",
        },
        {
          value: "question03",
          text: "De 1 a 5, sendo 1 muito difícil e 5 muito fácil, como foi sua navegação na plataforma?",
        },
        {
          value: "question04",
          text: "De 1 a 5, sendo 1 muito ruim e 5 muito bom, qual a sua opinião sobre a forma como os temas foram explicados?",
        },
        {
          value: "question05",
          text: "De 1 a 5, sendo 1 nada e 5 muito, quanto você sente que a Escola de Mudadores contribuiu para ampliar seu conhecimento sobre direitos humanos?",
        },
        {
          value: "question06",
          text: "De 1 a 5, sendo 1 nada e 5 muito, quanto você sente que a Escola de Mudadores contribuiu para ampliar seu conhecimento sobre meio ambiente?",
        },
        {
          value: "question07",
          text: "De 1 a 5, sendo 1 nada e 5 muito, quanto você sente que a Escola de Mudadores contribuiu para ampliar seu conhecimento sobre enfrentamento da violência?",
        },
        {
          value: "question08",
          text: "Depois de percorrer a trilha de aprendizagem da Escola de Mudadores, quanto você se sente preparado para exercer seu voto nas eleições de 2022 (De 1 a 5, sendo 1 nada e 5 muito)?",
        },
        {
          value: "question09",
          text: "Você sente que poderá utilizar os aprendizados de hoje no seu dia a dia?",
        },
        {
          value: "question10",
          text: "Ao concluir a trilha de aprendizagem, você se sente mais interessado em participar ativamente da política?",
        },
        {
          value: "question11",
          text: "Ao concluir a trilha de aprendizagem, você se sente mais interessado em se envolver com ações que promovem os direitos humanos e o meio ambiente?",
        },
        {
          value: "question12",
          text: "Se você é educador e trabalhou esse conteúdo em sala de aula, quanto você sente que o material te apoiou dialogar sobre política e os demais temas propostos (de 1 a 5, sendo 1 nada e 5 muito)?",
        },
        {
          value: "question13",
          text: "Se você é educador e trabalhou esse conteúdo em sala de aula, aproximadamente quantos estudantes participaram das discussões?",
        },
        {
          value: "question14",
          text: "Em uma palavra, como você definiria a sua experiência na Escola de Mudadores?",
        },
      ];
    },
    dataToExport: function () {
      return [
        { value: "openAt", text: "Iniciou em" },
        { value: "closedAt", text: "Encerrou em" },
        { value: "email", text: "Email" },
        { value: "name", text: "Nome" },
        { value: "state", text: "Estado" },
        { value: "city", text: "Cidade" },
        { value: "age", text: "Faixa de idade" },
        { value: "gender", text: "Gênero" },
        { value: "otherGender", text: "Gênero especificado" },
        { value: "ethnicity", text: "Cor/raça/etnia" },
        { value: "firstTimeVoting", text: "Votará pela primeira vez em 2022" },
        { value: "ocupation", text: "Atualmente" },
        { value: "isTeacher", text: "Professor" },
        { value: "teachingAt", text: "Ensina em" },
        { value: "hasDisability", text: "Possui deficiência" },
        { value: "disability", text: "Tipo de sua(s) deficiência(s)" },
        {
          value: "cameFrom",
          text: "Como ficou sabendo da Escola de Mudadores?",
        },
        {
          value: "humanRightsKnowledge",
          text: "Conhecimento sobre direitos humanos",
        },
        {
          value: "enviromentKnowledge",
          text: "Conhecimento sobre meio ambiente",
        },
        {
          value: "violenceKnowledge",
          text: "Conhecimento sobre enfrentamento da violência",
        },
        {
          value: "readyForElection",
          text: "Sente que está pronto para eleição",
        },
        {
          value: "question01",
          text: "De 1 a 5 | Qual a sua opinião sobre a qualidade das videoaulas?",
        },
        {
          value: "question02",
          text: "De 1 a 5 | Qual a sua opinião sobre a qualidade dos materiais de apoio?",
        },
        {
          value: "question03",
          text: "De 1 a 5 | Como foi sua navegação na plataforma?",
        },
        {
          value: "question04",
          text: "De 1 a 5 | Qual a sua opinião sobre a forma como os temas foram explicados?",
        },
        {
          value: "question05",
          text: "De 1 a 5 | Quanto você sente que a Escola de Mudadores contribuiu para ampliar seu conhecimento sobre direitos humanos?",
        },
        {
          value: "question06",
          text: "De 1 a 5 | Quanto você sente que a Escola de Mudadores contribuiu para ampliar seu conhecimento sobre meio ambiente?",
        },
        {
          value: "question07",
          text: "De 1 a 5 | Quanto você sente que a Escola de Mudadores contribuiu para ampliar seu conhecimento sobre enfrentamento da violência?",
        },
        {
          value: "question08",
          text: "De 1 a 5 | Depois de percorrer a trilha de aprendizagem da Escola de Mudadores. Quanto você se sente preparado para exercer seu voto nas eleições de 2022?",
        },
        {
          value: "question09",
          text: "Você sente que poderá utilizar os aprendizados de hoje no seu dia a dia?",
        },
        {
          value: "question10",
          text: "Ao concluir a trilha de aprendizagem | Você se sente mais interessado em participar ativamente da política?",
        },
        {
          value: "question11",
          text: "Ao concluir a trilha de aprendizagem | Você se sente mais interessado em se envolver com ações que promovem os direitos humanos e o meio ambiente?",
        },
        {
          value: "question12",
          text: "De 1 a 5 | Se você é educador e trabalhou esse conteúdo em sala de aula. Quanto você sente que o material te apoiou dialogar sobre política e os demais temas propostos?",
        },
        {
          value: "question13",
          text: "Se você é educador e trabalhou esse conteúdo em sala de aula. Aproximadamente quantos estudantes participaram das discussões?",
        },
        {
          value: "question14",
          text: "Em uma palavra | Como você definiria a sua experiência na Escola de Mudadores?",
        },
      ];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      moment.locale("pt");
      this.submitFilter();
    },
    submitFilter() {
      this.items = [];
      this.getter = new DataGetter(
        [
          { id: "trackRecord", type: "collection" },
          { id: "aprendizagem", type: "doc" },
          { id: "userStats", type: "collection" },
        ],
        {
          pageSize: this.pageSize,
          orderBy: this.orderBy,
          orderType: this.orderType,
        },
        this.handleDataLoadSuccessCallback,
        this.handleDataLoadErrorCallback
      );
    },
    nextPage() {
      if (this.loading) return;
      this.loading = true;
      this.getter.get(
        this.handleDataLoadSuccessCallback,
        this.handleDataLoadErrorCallback
      );
    },
    handleDataLoadSuccessCallback(data) {
      this.items = this.items.concat(data.items);
      this.hasNext = data.hasNext;
      this.loading = false;
    },
    handleDataLoadErrorCallback(error) {
      console.log(error);
      this.loading = false;
    },
    handleRowClick(item) {
      if (this.selected.indexOf(item) > -1) this.selected = [];
      else this.selected = [item];
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatField(value) {
      if (value == null || value === "") return "-";
      if (value === true) return "Sim";
      if (value === false) return "Não";
      if (value instanceof Date) return this.formatDate(value);
      return value;
    },
    async exportAndDownload() {
      var formatedData = {
        fields: [],
        data: this.items,
      };

      var formatedValuesToExport = this.items.map((item) => {
        item.openAt = new Date(item.openAt).toLocaleDateString("pt-BR");
        item.closedAt = new Date(item.closedAt).toLocaleDateString("pt-BR");
        item.firstTimeVoting === true
          ? (item.firstTimeVoting = "Sim")
          : (item.firstTimeVoting = "Não");
        item.isTeacher === true
          ? (item.isTeacher = "Sim")
          : (item.isTeacher = "Não");
        item.hasDisability === true
          ? (item.hasDisability = "Sim")
          : (item.hasDisability = "Não");
        if (item.otherGender === "" || item.otherGender === null) {
          item.otherGender = "-";
        }
        if (item.teachingAt === "" || item.teachingAt === null) {
          item.teachingAt = "-";
        }
        if (item.disability === "" || item.disability === null) {
          item.disability = "-";
        }
        if (item.question13 === "" || item.question13 === null) {
          item.question13 = "-";
        }
        if (item.question14 === "" || item.question14 === null) {
          item.question14 = "-";
        }
        return item;
      });

      this.submitFilter();
      formatedData.data = formatedValuesToExport;

      this.dataToExport.forEach((header) => {
        formatedData.fields.push(header.value);
      });

      var csv = Papa.unparse(formatedData);

      this.dataToExport.forEach((header) => {
        csv = csv.replace(header.value, header.text);
      });

      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {
        type: "text/csv",
      });

      downloadLink = document.createElement("a");

      var fileName =
        new Date(Date.now())
          .toLocaleString()
          .split(",")[0]
          .replace("/", "_")
          .replace(" ", "_") + ".csv";
      fileName = `${fileName}`;

      downloadLink.download = fileName;

      downloadLink.href = window.URL.createObjectURL(csvFile);

      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);

      downloadLink.click();
    },
  },
};
</script>
<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
.profile-field {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.profile-field:last-of-type {
  border-bottom: 0;
}
</style>
