<template>
  <div class="pt-10">
    <v-container>
      <v-tabs v-model="tab" class="pb-4">
        <v-tab>Cadastros</v-tab>
        <v-tab>Conclusões</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <user-list></user-list>
        </v-tab-item>
        <v-tab-item>
          <data-list></data-list>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <Navbar />
  </div>
</template>
<script>
import UserList from "../components/admin/UserList.vue";
import DataList from "../components/admin/DataList.vue";
import Navbar from "../components/Navbar.vue";
export default {
  name: "Admin",
  components: { UserList, DataList, Navbar },
  data: () => ({ tab: 0 }),
};
</script>