import { db } from "./firebase";

// let pageSize = 0;
// let orderBy = null;
// let orderType = "desc";

// let querySource = null;
// let currentQuery = null;
// let snapshotListener = null;
// let lastDoc = null;

// function reset() {
//     currentQuery = null;
//     lastDoc = null;
//     orderBy = null;
//     orderType = 'desc';
//     pageSize = 20
// }

// export async function get(successCallback, errorCallback) {
//     try {
//         currentQuery = querySource;
//         if (orderBy !== null)
//             currentQuery = currentQuery.orderBy(orderBy, orderType);
//         if (lastDoc !== null)
//             currentQuery = currentQuery.startAt(lastDoc)
//         if (pageSize > 0)
//             currentQuery = currentQuery.limit(pageSize + 1)
//         console.log(currentQuery)
//         if (snapshotListener != null)
//             snapshotListener();
//         snapshotListener = currentQuery.onSnapshot((snap) => { handleGetResponde(snap, successCallback) }, errorCallback)

//     } catch (error) {
//         errorCallback(error);
//     }
// }

// async function handleGetResponde(snap, successCallback) {
//     let responseObject = {
//         items: [],
//         hasNext: false
//     }

//     if (snap.docs == 0) {
//         successCallback(responseObject)
//         return;
//     }

//     let docs = snap.docs;

//     if (docs.length == pageSize + 1) {
//         lastDoc = docs.pop();
//         responseObject.hasNext = true;
//     }
//     else {
//         lastDoc = docs.length[docs.length - 1]
//         responseObject.hasNext = false;
//     }

//     responseObject.items = docs.map(doc => doc.data());

//     successCallback(responseObject)
// }

// export async function initialize(sourceParams, queryParams) {

//     reset();

//     if (queryParams.pageSize)
//         pageSize = queryParams.pageSize

//     if (queryParams.orderBy)
//         orderBy = queryParams.orderBy

//     if (queryParams.orderType)
//         orderType = queryParams.orderType

//     querySource = null;
//     sourceParams.forEach(param => {
//         if (querySource === null) {
//             if (param.type === 'collection')
//                 querySource = db.collection(param.id)
//             else if (param.type === 'doc')
//                 querySource = db.doc(param.id)
//         }
//         else {
//             if (param.type === 'collection')
//                 querySource = querySource.collection(param.id)
//             else if (param.type === 'doc')
//                 querySource = querySource.doc(param.id)
//         }
//     })

// }

export default class DataGetter {
  constructor(sourceParams, queryParams, successCallback, errorCallback) {
    this.pageSize = 0;
    this.orderBy = null;
    this.orderType = "desc";

    this.querySource = null;
    this.currentQuery = null;
    this.snapshotListener = null;
    this.lastDoc = null;

    this.initialize(sourceParams, queryParams);
    this.get(successCallback, errorCallback);
  }

  async initialize(sourceParams, queryParams) {
    this.reset();

    if (queryParams.pageSize) this.pageSize = queryParams.pageSize;

    if (queryParams.orderBy) this.orderBy = queryParams.orderBy;

    if (queryParams.orderType) this.orderType = queryParams.orderType;

    this.querySource = null;
    sourceParams.forEach((param) => {
      if (this.querySource === null) {
        if (param.type === "collection")
          this.querySource = db.collection(param.id);
        else if (param.type === "doc") this.querySource = db.doc(param.id);
      } else {
        if (param.type === "collection")
          this.querySource = this.querySource.collection(param.id);
        else if (param.type === "doc")
          this.querySource = this.querySource.doc(param.id);
      }
    });
  }

  reset() {
    this.currentQuery = null;
    this.lastDoc = null;
    this.orderBy = null;
    this.orderType = "desc";
    this.pageSize = 20;
  }

  async get(successCallback, errorCallback) {
    try {
      this.currentQuery = this.querySource;
      if (this.orderBy !== null)
        this.currentQuery = this.currentQuery.orderBy(
          this.orderBy,
          this.orderType
        );
      if (this.lastDoc !== null)
        this.currentQuery = this.currentQuery.startAt(this.lastDoc);
      if (this.pageSize > 0)
        this.currentQuery = this.currentQuery.limit(this.pageSize + 1);

      if (this.snapshotListener != null) this.snapshotListener();
      this.snapshotListener = this.currentQuery.onSnapshot((snap) => {
        this.handleGetResponde(snap, successCallback);
      }, errorCallback);
    } catch (error) {
      errorCallback(error);
    }
  }

  async handleGetResponde(snap, successCallback) {
    let responseObject = {
      items: [],
      hasNext: false,
    };

    if (snap.docs == 0) {
      successCallback(responseObject);
      return;
    }

    let docs = snap.docs;

    if (docs.length == this.pageSize + 1) {
      this.lastDoc = docs.pop();
      responseObject.hasNext = true;
    } else {
      this.lastDoc = docs.length[docs.length - 1];
      responseObject.hasNext = false;
    }

    responseObject.items = docs.map((doc) => doc.data());

    successCallback(responseObject);
  }
}
